import payload_plugin_BwjP8ARzzD from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.32.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_sdeymzv4Sj from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_bufferutil@4.0.9_db0@0.2.1_ioredis@5.4_pmilobqxfcxq37ec3ao4x65pta/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_K22Vi8v5Wn from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_rollup@4.32.0_typescript@5.7.3_vite@6.0.11_@types+n_syayrnpreb4m74mhsnlckuhjea/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_P0fKF8OxU7 from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_bufferutil@4.0.9_db0@0.2.1_ioredis@5.4_pmilobqxfcxq37ec3ao4x65pta/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_iuDCqBMV3E from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_bufferutil@4.0.9_db0@0.2.1_ioredis@5.4_pmilobqxfcxq37ec3ao4x65pta/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_A0heN0Xqpb from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_bufferutil@4.0.9_db0@0.2.1_ioredis@5.4_pmilobqxfcxq37ec3ao4x65pta/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_SVb7jmjRvC from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_bufferutil@4.0.9_db0@0.2.1_ioredis@5.4_pmilobqxfcxq37ec3ao4x65pta/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_UtFUMIT4s3 from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_bufferutil@4.0.9_db0@0.2.1_ioredis@5.4_pmilobqxfcxq37ec3ao4x65pta/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_x0B44rnwTy from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_bufferutil@4.0.9_db0@0.2.1_ioredis@5.4_pmilobqxfcxq37ec3ao4x65pta/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_53oHI1IsTV from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.32.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/addicted.nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_FaI9Ytnoez from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_bufferutil@4.0.9_db0@0.2.1_ioredis@5.4_pmilobqxfcxq37ec3ao4x65pta/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Jrh4Kp8SdM from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7_44vdsfvcxaptbjbncapehtf54q/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import vuetify_no_client_hints_0dOcYRpFEY from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_rollup@4.32.0_typescript@5.7.3_vite@6.0.11_@types+n_syayrnpreb4m74mhsnlckuhjea/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import plugin_wy0B721ODc from "/opt/buildhome/repo/addicted.nuxt/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import cloudflare_client_l7hHizcRfX from "/opt/buildhome/repo/addicted.nuxt/plugins/cloudflare.client.ts";
import matomo_client_Ed2EqINkhd from "/opt/buildhome/repo/addicted.nuxt/plugins/matomo.client.ts";
import sentry_client_shVUlIjFLk from "/opt/buildhome/repo/addicted.nuxt/plugins/sentry.client.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/opt/buildhome/repo/addicted.nuxt/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  payload_plugin_BwjP8ARzzD,
  revive_payload_client_sdeymzv4Sj,
  vuetify_icons_K22Vi8v5Wn,
  unhead_P0fKF8OxU7,
  router_iuDCqBMV3E,
  payload_client_A0heN0Xqpb,
  navigation_repaint_client_SVb7jmjRvC,
  check_outdated_build_client_UtFUMIT4s3,
  chunk_reload_client_x0B44rnwTy,
  plugin_vue3_53oHI1IsTV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_FaI9Ytnoez,
  plugin_Jrh4Kp8SdM,
  vuetify_no_client_hints_0dOcYRpFEY,
  plugin_wy0B721ODc,
  cloudflare_client_l7hHizcRfX,
  matomo_client_Ed2EqINkhd,
  sentry_client_shVUlIjFLk,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]